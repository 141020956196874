import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="What Is Concept Design & Rapid Prototyping?"
            description="We break down concept design, rapid prototyping, and its advantages in our latest blog post. For your manufacturing needs, Painted Rhino’s here to help!"
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="From Concept Design to Prototype in 24 Hours? 3D Printing Makes It Happen"
            date="April 30, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>The timeline between concept design and the finished product is shrinking considerably, thanks to 3D printing.</p>

                  <p>
                     The art of 3D printing has come a long way since its development days in the 1980s. In the early days, it was used mainly in
                     research and development labs. Today, 3D printing is being used in seemingly endless ways.
                  </p>

                  <p>One popular application is creating product prototypes quickly. </p>

                  <p>
                     The traditional way of creating a prototype - manufacturing the item in much the same way it would be produced - is not only
                     costly but takes time.
                  </p>

                  <p>But now, rapid prototyping, using 3D printing, is changing the game.</p>

                  <h2>What is Rapid Prototyping?</h2>
                  <p>As you may have guessed, rapid prototyping refers to creating a prototype FAST.</p>

                  <p>
                     In rapid prototyping, a three-dimensional object is fabricated from a CAD or digital 3D model. The objects created can include
                     parts or assemblies used to construct a functional prototype or a single object that <i>is </i>the prototype.
                  </p>

                  <p>Using 3D printing, you can have a 24-hour design cycle that looks like this:</p>

                  <ul>
                     <li>
                        <p>Concept and design during the workday</p>
                     </li>
                     <li>
                        <p>3D printing completed overnight</p>
                     </li>
                     <li>
                        <p>Clean, test, and tweak the next day</p>
                     </li>
                     <li>
                        <p>Repeat the steps as needed</p>
                     </li>
                  </ul>

                  <p>
                     The process of 3D printing is known as additive manufacturing, as opposed to subtractive manufacturing. With additive
                     manufacturing, objects are built by adding material layer by layer. Subtractive manufacturing removes material to create objects.
                  </p>

                  <h2>What are the Benefits?</h2>
                  <p>
                     The top business benefit of rapid prototyping is getting products to market faster. It removes the bottleneck that the
                     traditional manufacturing process can create.
                  </p>

                  <p>
                     From a concept and design perspective, rapid prototyping gives product designers and engineers the ability to go beyond virtual
                     visualization. 
                  </p>

                  <p>
                     There’s nothing like understanding the look and feel of a product design until you have it in your hands. And rapid prototyping
                     provides an incredible amount of flexibility to refine concepts, try out new ideas, and test functionality. Plus, design flaws
                     can be discovered early on and quickly corrected.
                  </p>

                  <p>
                     Product designers and engineers can also share physical prototypes with colleagues, clients, and collaborators who can give their
                     hands-on feedback. 
                  </p>

                  <p>Rapid prototyping is a win-win for everyone.</p>

                  <h2>How to Get a 3D Prototype Made</h2>
                  <p>Painted Rhino can bring your design to life!</p>

                  <p>
                     We use 3D printing to quickly turn your digital model into a 3D prototype or final product. We start by slicing, a process that
                     divides or “slices” your model into layers. After slicing, your 3D model is ready to be printed layer by layer.  We’ll help you
                     create furniture, industrial parts, movie props, scale models, automotive and aviation parts, and more. 
                  </p>

                  <p>We’re also product designers and engineers.</p>

                  <p>
                     You can come to us with your concept or idea, and we’ll run with it. We are a team of innovators who explore what’s possible,
                     refine ideas and build the best product. We have state-of-the-art machinery that can transform your concept into reality.
                  </p>

                  <p>
                     <a data-modal-open="modal-contact">Contact us</a> today to get started!
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/concept-design-rapid-prototyping.jpeg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
